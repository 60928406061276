class Barcode {
    /**
     * Отгрузочный ящик
     * @returns {string}
     */
    static boxBarcodePrefix() {
        return 'QZ';
    }

    /**
     * Отгрузочная ячейка
     * @returns {string}
     */
    static zoneBarcodePrefix() {
        return 'ZN';
    }

    /**
     * Паллета
     * @returns {string}
     */
    static palletBarcodePrefix() {
        return 'PT';
    }

    /**
     * Акт отгрузки (накладная)
     * @returns {string}
     */
    static shipmentActPrefix() {
        return 'MAN|';
    }

    /**
     * Весы
     * @returns {string}
     */
    static weighingScalesPrefix() {
        return 'WS';
    }

    /**
     * Гастроемкости
     * @returns {string}
     */
    static gastronormContainerPrefix() {
        return 'GC';
    }

    /**
     * Акт передачи
     * @return {string}
     */
    static materialTransferActPrefix() {
        return 'MT';
    }
}


export default Barcode
