<template>
    <div>
        <div v-if="loading" class="alert alert-info text-center"><i class="fas fa-spinner fa-spin"></i></div>
        <div v-else>
            <div v-if="!zone" class="alert alert-info text-center">
                <template v-if="loadingZone">
                    <i class="fas fa-spinner fa-spin"></i> <br>
                </template>
                Отстанируйте ШК ячейки, <br> чтобы начать
            </div>
            <template v-else>
                <div class="alert alert-light text-center">
                    <div>Ячейка <b>{{zone.name}}</b></div>
                    <template v-if="zone.capacity">
                        <div>Вместимость: {{zone.capacity}}</div>
                        <div>Свободно: {{parseInt(zone.capacity) - zone.stockItems.length}}</div>
                    </template>
                </div>
                <div class="alert alert-info text-center mb-0 p-1">
                    <template v-if="loadingBoxTmpAttachment">
                        <i class="fas fa-spinner fa-spin"></i> <br>
                    </template>
                    Поочередно сканируйте ШК ящиков, чтобы назначить их на ячейку
                </div>
                <div class="alert alert-light text-center mb-0 p-1">затем</div>
                <div class="alert alert-info text-center p-1">
                    <template v-if="loadingBoxAttachment">
                        <i class="fas fa-spinner fa-spin"></i> <br>
                    </template>
                    Отсканируйте ШК ячейки, чтобы завершить
                </div>
            </template>
            <hr>
            <div class="alert alert-light">Ящиков: {{tmpBoxes.length}}</div>
            <div v-for="content in tmpBoxes"
                 @dblclick="detach(content)"
                 :key="content.id"
                 class="alert alert-dark mb-1 box-item">
                <span>{{content.box.barcode.code}}</span>
                <span class="pull-right">
                    <i v-if="!!content.detaching" class="fas fa-spinner fa-spin"></i>
                </span>
            </div>
            <div class="mb-3"></div>
        </div>
    </div>
</template>

<script>
    import {STOCK_ENDPOINT} from "../../../utils/endpoints";
    import Barcode from "../../../utils/Barcode";
    import {findIndex} from 'lodash';

    const BOX_BARCODE_PREFIX = Barcode.boxBarcodePrefix();
    const ZONE_BARCODE_PREFIX = Barcode.zoneBarcodePrefix();
    const CONTENT_WITH = [
        'box.barcode',
        'nomenclatureLot',
        'nomenclature.measureUnit',
        'stockItems'
    ];

    const ZONE_WITH = [
        'stockItems'
    ];

    export default {
        name: "OntoZone",
        data() {
            return {
                loading: false,
                tmpBoxes: [],

                loadingZone: false,
                zone: null,
                zoneConfirm: null,

                loadingBoxTmpAttachment: false, // навешиваем ящики на юзера
                loadingBoxAttachment: 0, // навешиваем ящики на ячейку
            };
        },
        watch: {
            loadingBoxAttachment(val, oldVal) {
                if (val === 0 && oldVal !== 0) {
                    this.$toast.success('Размещение ящиков завершено');
                    this.load();
                    if (this.zone && !this.loadingZone) {
                        this.loadZone(this.zone.name);
                    }
                }
            }
        },
        methods: {
            load() {
                if (this.loading) {
                    return;
                }

                this.zone = null;
                this.zoneConfirm = null;

                this.loading = true;

                this.$http.get(STOCK_ENDPOINT + `/tmp_boxes`, {
                    params: {
                        without_loading: true,
                        with: CONTENT_WITH
                    }
                }).then(response => {
                    this.tmpBoxes = response.data;
                }).catch(response => {
                    this.$toast.error(response.data.message);
                }).finally(() => {
                    this.loading = false;
                });
            },
            onBarcodeScanned(data) {
                if (!this.zone) {
                    this.zoneScanned(data.barcode);
                    return;
                }

                if (data.barcode.startsWith(BOX_BARCODE_PREFIX)) {
                    this.boxScanned(data.barcode);
                    return;
                }

                if (data.barcode.startsWith(ZONE_BARCODE_PREFIX)) {
                    this.zoneConfirmed(data.barcode);
                    return;
                }

                this.$toast.error('Неверный ШК');
            },
            zoneScanned(barcode) {
                if (!barcode.startsWith(ZONE_BARCODE_PREFIX)) {
                    this.$toast.error('Неверный ШК. Отсканируйте ШК ячейки');
                    return;
                }

                this.loadZone(barcode);
            },
            loadZone(barcode) {
                if (this.loadingZone) {
                    this.$toast.warning('Дождитесь завершения предыдущей операции');
                    return;
                }

                this.loadingZone = true;
                this.$http.get(STOCK_ENDPOINT + '/location', {
                    params: {
                        without_loading: true,

                        type: 'zone',
                        barcode: barcode,
                        with: ZONE_WITH
                    }
                }).then(response => {
                    this.zone = response.data;
                }).catch(response => {
                    this.$toast.error(response.data.message);
                }).finally(() => {
                    this.loadingZone = false;
                });
            },
            zoneConfirmed(barcode) {
                if (this.loadingZone || this.loadingBoxTmpAttachment || this.loadingBoxAttachment) {
                    this.$toast.warning('Дождитесь завершения предыдущей операции');
                    return;
                }

                if (barcode === this.zone.code) {
                    this.zoneConfirm = barcode;
                    this.tmpBoxes.map(c => {
                        this.attachBox(c.box.barcode.code, false, true);
                    });
                    return;
                }

                this.$toast.error('Неверная ячейка');
            },
            boxScanned(barcode) {
                if (!barcode.startsWith(BOX_BARCODE_PREFIX)) {
                    this.$toast.error('Неверный ШК. Отсканируйте ШК ящика');
                    return;
                }
                this.attachBox(barcode);
            },
            attachBox(barcode, tmp = true, force = false) {
                if (this.loadingZone || this.loadingBoxTmpAttachment) {
                    this.$toast.warning('Дождитесь завершения предыдущей операции');
                    return;
                }

                if (tmp && (parseInt(this.zone.capacity) - this.zone.stockItems.length) <= this.tmpBoxes.length) {
                    this.$toast.error('Ячейка переполнена');
                    return;
                }

                if (tmp) {
                    this.loadingBoxTmpAttachment = true;
                } else {
                    this.loadingBoxAttachment++;
                }

                let data = {
                    box_barcode: barcode,
                    with: CONTENT_WITH,
                    force: force ? 1 : 0,
                };

                if (!!this.zoneConfirm && this.zoneConfirm === this.zone.code) {
                    data.location_type = 'zone';
                    data.location_barcode = this.zone.code;
                }

                data.location_zone_type = 'zone';
                data.location_zone_barcode = this.zone.code;

                this.$http.post(STOCK_ENDPOINT + '/assign_box', data, {
                    params: {without_loading: true}
                }).then(response => {
                    let content = response.data.content;

                    if (!response.data.success && !!response.data.message) {
                        const showButton = response.data.showButton;
                        if (showButton) {
                            this.$toast.warning(response.data.message, 'Ошибка', {
                                timeout: 10000,
                                buttons: [
                                    [
                                        '<button class="btn btn-danger">Переместить в текущую ячейку</button>',
                                        (instance, toast) => {
                                            instance.hide({}, toast);
                                            this.attachBox(barcode, tmp, true);
                                        }
                                    ]
                                ]
                            });
                        } else {
                            this.$toast.error(response.data.message);
                        }
                        return;
                    }

                    if (tmp) {
                        let index = findIndex(this.tmpBoxes, b => b.box_id === content.box_id);
                        if (index !== -1) {
                            this.$toast.info('Этот ящик уже есть в списке');
                            this.deleteFromList(content);
                        }

                        this.tmpBoxes.unshift(content);
                    } else {
                        this.deleteFromList(content);
                    }
                }).catch(response => {
                    this.$toast.error(response.data.message);
                }).finally(() => {
                    if (tmp) {
                        this.loadingBoxTmpAttachment = false;
                    } else {
                        this.loadingBoxAttachment--;
                    }
                });
            },
            detach(content) {
                if (!!content.detaching) {

                    return;
                }

                if (!window.confirm(`Удалить ящик ${content.box.barcode.code} из списка?`)) {
                    return;
                }

                content.detaching = true;
                this.$forceUpdate();
                let data = {
                    box_barcode: content.box.barcode.code,
                };
                this.$http.post(STOCK_ENDPOINT + '/detach_box', data, {
                    params: {without_loading: true}
                }).then(response => {
                    this.deleteFromList(content);
                }).catch(response => {
                    this.$toast.error(response.data.message);
                }).finally(() => {
                    content.detaching = false;
                    this.$forceUpdate();
                });
            },
            deleteFromList(content) {
                let index = findIndex(this.tmpBoxes, b => b.box.id === content.box.id);
                if (index !== -1) {
                    this.tmpBoxes.splice(index, 1);
                }
            }
        },
        mounted() {
            this.load();
            this.$root.listenToBarcodeScanning(this);
        }
    }
</script>

<style scoped>
    .box-item {
        animation: boxitem 1s ease-in-out;
    }

    @keyframes boxitem {
        0% {
            background-color: #dbf2e3;
        }

        100% {
            background-color: #d5d7d8;
        }
    }
</style>
